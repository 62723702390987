<script setup lang="ts">
</script>
<template>
    <div class="logo">
      <!-- <NuxtLink to="/" >
        <img src="/images/logos/logo.svg" />
      </NuxtLink> -->
       <h1 class="text-primary">TREELET</h1>
    </div>
  </template>
